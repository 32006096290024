import React, { useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Image, Placeholder } from "cloudinary-react"
import data from "../../../config.json"

const NuvoImage = ({
  publicId,
  alt = "",
  quality,
  wrapperClassName,
  className,
  useAR = true,
  children,
  hasLoaded,
  placeholder = true,
  ...rest
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  if (!publicId) return <></>

  // default image object if match isn't found
  let image = { public_id: publicId, context: { custom: { alt: alt } } }

  // find image object
  const match = useMemo(
    () => data.images.resources.find((node) => node.public_id === publicId),
    [data, publicId]
  )

  if (match) {
    image = match
  }

  const imageQuality = quality ?? 99

  const imageWrapperClasses = classNames(wrapperClassName, {
    "image-meta__wrapper": true,
  })

  const imageClasses = classNames(className, {
    "image-meta__image": true,
  })

  const handleLoaded = () => {
    setIsLoaded(true)

    if (hasLoaded) {
      hasLoaded(image.public_id)
    }
  }

  if (useAR) {
    return (
      <div
        style={{
          position: "relative",
          height: 0,
          paddingTop: (image.height / image.width) * 100 + "%",
        }}
        className={imageWrapperClasses}
      >
        {!isLoaded && (
          <div
            className="img-loading"
            style={{
              position: "absolute",
              inset: 0,
              display: isLoaded ? "hidden" : "block",
            }}
          ></div>
        )}
        <Image
          publicId={image.public_id}
          style={{
            visibility: isLoaded ? "visible" : "hidden",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
          }}
          onLoad={() => handleLoaded()}
          loading="lazy"
          responsive
          quality={imageQuality}
          alt={image.context.custom.alt}
          {...rest}
          className={imageClasses}
        >
          {children}
          {placeholder && <Placeholder type="blur" />}
        </Image>
      </div>
    )
  } else {
    return (
      <Image
        style={{
          visibility: isLoaded ? "visible" : "hidden",
        }}
        onLoad={() => handleLoaded()}
        publicId={image.public_id}
        loading="lazy"
        responsive
        quality={imageQuality}
        alt={image.context.custom.alt}
        {...rest}
        className={imageClasses}
      >
        {children}
        {placeholder && <Placeholder type="blur" />}
      </Image>
    )
  }
}

NuvoImage.propTypes = {
  publicId: PropTypes.string.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
  useAR: PropTypes.bool,
  wrapperClassName: PropTypes.string,
}

export default React.memo(NuvoImage)

NuvoImage.defaultProps = {
  cloudName: "nuvolum",
  width: "auto",
}
