import React from 'react'
import ImageMeta from "../ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import classnames from 'classnames'


const ImageText = ({ heading, imageId, textSection, extraText, imageTextReverse, sideColumnIs }) => {
    const isTextBeforeImage = classnames({
        "flex-row-reverse": !imageTextReverse
    })
    return (
        <div className="body-sections procedure-image-text">

            {!heading
                ? <></>
                : <div className="columns">
                        <div className={`column is-${sideColumnIs}`}></div>
                        <div className="column">
                            <MarkdownViewer markdown={heading} />
                        </div>
                        <div className={`column is-${sideColumnIs}`}></div>
                    </div>
            }
            
            <div className={`columns is-vcentered ${isTextBeforeImage}`}>
                <div className={`column is-${sideColumnIs}`}></div>
                <div className="column">
                    <ImageMeta
                        cloudname="nuvolum"
                        publicId={imageId}
                        responsive
                    />
                </div>
                
                <div className="column is-1"></div>
                
                <div className="column">
                    <MarkdownViewer markdown={textSection} />
                </div>
                <div className={`column is-${sideColumnIs}`}></div>
            </div>

            {!extraText 
                ? <></>
                : <div className="columns extra-text" style={{marginTop: "1.5rem"}}>
                    <div className={`column is-${sideColumnIs}`}></div>
                    <div className="column">
                        <MarkdownViewer markdown={extraText} />
                    </div>
                    <div className={`column is-${sideColumnIs}`}></div>
                </div>
            }
        </div>
    )
}

export default ImageText
