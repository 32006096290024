import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"
import Button from "@bit/azheng.joshua-tree.button"
import { Image, Text } from "./Core"
import TheaterVideo from "@components/Theater/TheaterVideo"
import DropdownButton from "../components/DropdownButton"

// import "./SingleProcedureHero.scss"

const SingleProcedureHero = ({
  hero,
  heading,
  language,
  youtube,
  dropdownButton,
  goBackUrl,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const copyright = `Copyright ©Nuvolum ${new Date().getFullYear()}. All Rights Reserved.`

  return (
    <div
      className={`sph ${hero?.position ? hero.position : ""} rounded-bottom`}
    >
      {goBackUrl && (
        <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={goBackUrl}
        />
      )}
      <div className="sph__heading">
        <Text as="h1" text={heading} />
        <div className="sph__btn-stack">
          {youtube && (
            <div>
              <TheaterVideo
                language={language}
                videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
                controls
                playing
                //   overVideo
                hasWatchVideo
                buttonClass="contained is-hidden-touch"
              />
            </div>
          )}
          {dropdownButton && dropdownButton.hasThisSection && (
            <div className="is-hidden-touch mt-1">
              <DropdownButton
                isCenteredMobile
                heading="Other Locations"
                links={dropdownButton.links}
              />
            </div>
          )}
        </div>
        {youtube && (
          <div className=" is-hidden-desktop">
            <TheaterVideo
              language={language}
              videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
              controls
              playing
              asWrapper
              wrapperClassName="sph__mobile-theater"
              hasWatchVideo
              buttonClass="contained"
            >
              <FontAwesomeIcon icon={faPlay} />
            </TheaterVideo>
          </div>
        )}
        <span className="sph__copyright">{copyright}</span>
      </div>

      <div className="sph__theater">
        <div
          className={`sph__icon ${hero?.className || ""} ${
            hero?.animationStyle ? hero.animationStyle : "slide-left"
          } ${hero?.position ? hero.position : ""} ${
            isImageLoaded ? "animate" : ""
          }`}
        >
          <Image
            placeholder={false}
            useAR={false}
            className={`${hero?.size || ""} ${
              hero?.imageMobile ? "is-hidden-touch" : ""
            }`}
            hasLoaded={() => setIsImageLoaded(true)}
            publicId={hero?.image}
          />

          {hero?.imageMobile && (
            <Image
              placeholder={false}
              useAR={false}
              className={`is-hidden-desktop ${hero.size || ""} `}
              hasLoaded={() => setIsImageLoaded(true)}
              publicId={hero.imageMobile}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SingleProcedureHero
