import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ImageMeta from "../ImageMeta"

var classNames = require("classnames")
var showdown = require("showdown")

function createHtml(html) {
  return { __html: html }
}

class HeadingBlurbMedia extends React.Component {
  constructor(props) {
    super(props)
  }

  

  render() {
    const converter = new showdown.Converter()

    var headingLevel;
    var heading;

    if (this.props.headingLevel) {
      headingLevel = this.props.headingLevel
    } else {
      headingLevel = "h3"
    }

    switch(headingLevel) {
      case "h1":
        heading = <h1 className={this.props.headingLook} style={{ marginBottom: this.props.paragraph ? "" : "2rem" }} dangerouslySetInnerHTML={createHtml(this.props.heading)}></h1>
        break;
      case "h2":
        heading = <h2 className={this.props.headingLook} style={{ marginBottom: this.props.paragraph ? "" : "2rem", marginTop: this.props.customStyle === "Dental Implants" ? "7px" : "3rem" }} dangerouslySetInnerHTML={createHtml(this.props.heading)}></h2>
        break;
      case "h3":
        heading = <h3 className={this.props.headingLook + " has-text-centered-mobile"} style={{ marginBottom: this.props.paragraph ? "" : "2rem" }} dangerouslySetInnerHTML={createHtml(this.props.heading)}></h3>
        break;
      case "h4":
        heading = <h4 className={this.props.headingLook} style={{ marginBottom: this.props.paragraph ? "" : "2rem" }} dangerouslySetInnerHTML={createHtml(this.props.heading)}></h4>
        break;
      case "h5":
        heading = <h5 className={this.props.headingLook} style={{ marginBottom: this.props.paragraph ? "" : "2rem" }} dangerouslySetInnerHTML={createHtml(this.props.heading)}></h5>
        break;
      case "h6":
        heading = <h6 className={this.props.headingLook} style={{ marginBottom: this.props.paragraph ? "" : "2rem" }} dangerouslySetInnerHTML={createHtml(this.props.heading)}></h6>
        break;
    }

    return (
      <section className="section heading-blurb-video">
        {this.props.heading && (
          <div className="columns">
            <div
              className={`column is-${this.props.headingSideColumnIs}`}
            ></div>
            <div className="column">
            {heading}
              {this.props.paragraph && (
                <div
                  style={{ marginBottom: "2rem" }}
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(this.props.paragraph)
                  )}
                ></div>
              )}
            </div>
            <div
              className={`column is-${this.props.headingSideColumnIs}`}
            ></div>
          </div>
        )}
        <div className="columns">
          {!this.props.isMediaFullWidth && <div className={`column is-${this.props.videoSideColumnIs}`}></div>}
          <div className="column">
              <ImageMeta
                cloudName="nuvolum"
                publicId={this.props.youtubePhotoPublicId}
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
              ></ImageMeta>
              <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${this.props.youtube}`}
              controls={true}
              playing={true}
              overVideo={false}
              hasWatchVideo={true}
              buttonClass="contained"
            >
            </TheaterVideo>
          </div>
          {!this.props.isMediaFullWidth && <div className={`column is-${this.props.videoSideColumnIs}`}></div>}
        </div>
      </section>
    )
  }
}

HeadingBlurbMedia.propTypes = {
  headingSideColumnIs: PropTypes.number,
  isMediaFullWidth: PropTypes.bool,
  videoSideColumnIs: PropTypes.number,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  youtube: PropTypes.string,
  youtubePhotoPublicId: PropTypes.string,
}

export default HeadingBlurbMedia
